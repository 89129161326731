import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactWOW from 'react-wow';

class FeatureElement extends Component{
    render(){
        const {title,about,icon} = this.props;
    return(
        <ReactWOW animation="fadeInLeft" data-wow-delay=".3s">
        <div className="feature-box with-hover-img">
          <div className="icon">
            {icon}
          </div>
          <h3><Link to="#">{title}</Link></h3>
          <p>
          {about}
          </p>
          <div className="hover-img" style={{backgroundImage: 'url(assets/img/feature/01.jpg)'}} />
        </div>
        </ReactWOW>
        );
}
    
}

export default FeatureElement;