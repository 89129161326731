import React, { Component } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import ReactWOW from 'react-wow'
import { withNamespaces } from 'react-i18next';


class VideoBlock extends Component {
    
    state = {
        open: false,
        };
        onOpenModal = () => {
        this.setState({ open: true });
        };
        
        onCloseModal = () => {
        this.setState({ open: false });
        };
        render() {
        const { open } = this.state;
        const {t} = this.props;
    return (
        <section className="text-block bg-black with-pattern pt-115 pb-115" id="explore">
            <div className="container">
            <div className="row align-items-center justify-content-center">
                <div className="col-lg-6 col-md-10 order-2 order-lg-1">
                <div className="block-text">
                    <div className="section-title mb-20">
                   <h2>{t('videoTitle')}</h2>
                    </div>
                    <p className="pr-50">
                    {t('videoAbout')}
                    </p>
            </div>
                </div>
                <ReactWOW animation="fadeInRight" data-wow-delay=".3s">
                <div className="col-lg-6 col-md-10 order-1 order-lg-2">
                <div className="video-wrap video-wrap-two mb-small" style={{backgroundImage: 'url(assets/img/text-block/03.jpg)'}}>
                    <div className="popup-video"onClick={this.onOpenModal}><i className="fas fa-play" /></div>
                    <Modal open={open} onClose={this.onCloseModal} center classNames={{
                    modal: 'video-modal',
                    }}>
                    <iframe src="https://www.youtube.com/embed/_mFkIHBjVvA" title="title" allowFullScreen></iframe>
                    </Modal>
                </div>
                </div>
                </ReactWOW>
            </div>
            </div>
            <div className="pattern-wrap">
            <div className="pattern" />
            </div>
        </section>
        
    );
  }
}

export default withNamespaces()(VideoBlock);
