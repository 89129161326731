import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import {Row,Col} from 'react-bootstrap';
import AmenityElement from './AmenityElement';
import i18next from "i18next";


class Amenities extends Component { 
  render() {
    const {t} = this.props;

    return (
        <section className="counter-section" id="amenities">
        <div className="container">
        <div className="section-title mb-80 text-center">
            <h2>{t('nav5')}</h2>
          </div>
        <Row>
            <Col xs={12} md={4}>
       <AmenityElement
    name={t('amenityTitle1')}
    section={i18next.t('general', { returnObjects: true })}
    />
    </Col>
    <Col xs={12} md={4}>
       <AmenityElement
    name={t('amenityTitle2')}
    section={i18next.t('kitchen', { returnObjects: true })}
    />
    </Col>

 <Col xs={12} md={4}>
     <AmenityElement
    name={t('amenityTitle3')}
    section={i18next.t('outdoor', { returnObjects: true })}
    />
    </Col>
        </Row>
        </div>
      </section>
      
    );
  }
}

export default withNamespaces()(Amenities);
