import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import emailjs from "emailjs-com";
import { withNamespaces } from 'react-i18next';
import {Modal,Button} from 'react-bootstrap';



class Footertwo extends Component {
  constructor(props) {

    super(props);
    this.sendEmail = this.sendEmail.bind(this);
		this.handleClose = this.handleClose.bind(this);
    this.state = {
       redText: false,
      show: false,
      sent: false
    };
  }

  handleClose() {
		this.setState({ show: false });
	}

  componentDidMount() {
    window.addEventListener('scroll', () => {
      this.setState({
          isTop: window.scrollY > 300
      });
  }, false);
  }
  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  sendEmail(e) {
    e.preventDefault();
    emailjs
      .sendForm("service_nu7q9kf", "template_b6jzm8s", e.target, "user_Xgfl23887hg6rH6kqS9LG")
      .then/* (result) => {
        console.log(result.text);
/*           
        <div>

        </div> 
        
        },
        (error) => {
          console.log(error.text);
        },  */
      ();
 e.target.reset();
 this.setState({ show: true });
}


 render() {

    const {t} = this.props;
    const className = this.state.isTop ? 'active' : '';
    return (
      <div id="contact">
        {/*====== Back to Top ======*/}
        <Link to="#" className={`back-to-top ${className}`} id="backToTop" onClick={() => this.scrollToTop()}>
          <i className="fal fa-angle-double-up" />
        </Link>
        {/*====== FOOTER START ======*/}
        <footer className="footer-two">
          <div className="footer-widget-area pt-100 pb-50">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-sm-6 order-1 ">
                  {/* Site Info Widget */}
                  <div className="contact-maps mb-30"> 
                  <iframe title="gmap" className="map" width="400" height="500" id="gmap_canvas" 
src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2910.47126716985!2d17.238045615212275!3d43.15763099235218!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x134afe80494182b5%3A0x782b5e1a5a315291!2sVilla%20Gojun!5e0!3m2!1sen!2shr!4v1647873355869!5m2!1sen!2shr" 
frameborder="0" 
scrolling="no" 
marginheight="0" 
marginwidth="0">
</iframe>                 </div>
                </div>             
                <div className="col-lg-6 col-sm-6 order-2 order-lg-3">
                  <div className="room-booking-form">
        <h5 className="title">{t('form')}</h5>
        <form onSubmit={this.sendEmail}>
        <div className="input-group input-group-two left-icon mb-20">
            <input className="input" type="text" placeholder={t('name')} name="userName" id="userName" required/>
          </div>
          <div className="input-group input-group-two left-icon mb-20">
            <input className="input" type="text" placeholder={t('email')} name="userEmail" id="userEmail"  required/> 
          </div>
          <div className="input-group input-group-two left-icon mb-20">
          <input className="input" type="date" name="startDate" id="arrival-date" required/>
          </div>
          <div className="input-group input-group-two left-icon mb-20">
              <input className="input" type="date" name="endDate" id="departure-date"required/>
          </div>
          <div className="input-group input-group-two left-icon mb-20">
            <textarea className="input" type="textarea" placeholder={t('msg')} name="message" id="userMsg" />
          </div>
          <div className="input-group">
            <button className="main-btn btn-filled form-btn">{t('form')}</button>
          </div>
        </form>
      </div>
                </div>
              </div>
            </div>
          </div>
          <div className="copyright-area pt-30 pb-30">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6 col-md-5 order-2 order-md-1">
                  <p className="copyright-text copyright-two">Copyright By Petar Gojun - 2022</p>
                </div>
              </div>
            </div>
          </div>
        </footer>
        {/*====== FOOTER END ======*/}
        <Modal show={this.state.show}  onHide={this.handleClose} >
                  <Modal.Header closeButton>
                    <Modal.Title>{t("modalHead")}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p>{t("modalBody")}</p>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button onClick={this.handleClose}  variant="secondary">{t("modalClose")}</Button>
                  </Modal.Footer>
                </Modal>
      </div>
    );
  }
}

export default withNamespaces() (Footertwo);
