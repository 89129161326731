import React, {Component} from "react";
import { Accordion, Card, ListGroup } from "react-bootstrap";


class AmenityElement extends Component {

  render(){
    const { section, name} = this.props;
const something = section.map((data) => {
        return <ListGroup.Item key={data.toString()}>{data}</ListGroup.Item>;
    });
  return (
    <Accordion defaultActiveKey="1">
    <Card className="card-head">
      <Accordion.Toggle  className="main-btn" as={Card.Header} eventKey="0">
        {name}
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="0">
      <ListGroup>
        <div>{something}</div>
        </ListGroup>
      </Accordion.Collapse>
    </Card>
  </Accordion>

/*     <Accordion defaultActiveKey="1">
      <Card
/*         style={{
          borderRadius: 0,
          textAlign: "center",
        }} 
      >
        <Card.Header >
          <Accordion.Toggle
            as={Card.Header}
        eventKey="0" 
          >
            {name}
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <ListGroup>
        <div>{something}</div>
        </ListGroup>
        </Accordion.Collapse>
      </Card>
    </Accordion> */
  );
            }
        }

/* AmenityElement.propTypes = {
  section: PropTypes.array,
  name: PropTypes.string,
  iconName: PropTypes.string,
};

AmenityElement.defaultProps = {
  section: null,
  name: null,
  iconName: null,
}; */

export default AmenityElement;