import img1 from '../../assets/img/gallery/01.jpg';
//import img2 from '../../assets/img/gallery/02.jpg';
import img3 from '../../assets/img/gallery/03.jpg';
import img4 from '../../assets/img/gallery/04.jpg';
import img5 from '../../assets/img/gallery/05.jpg';
import img6 from '../../assets/img/gallery/06.jpg';
import img7 from '../../assets/img/gallery/07.jpg';
import img8 from '../../assets/img/gallery/08.jpg';
import img9 from '../../assets/img/gallery/09.jpg';
import img10 from '../../assets/img/gallery/10.jpg';
import img11 from '../../assets/img/gallery/11.jpg';
import img12 from '../../assets/img/gallery/12.jpg';
import img13 from '../../assets/img/gallery/13.jpg';
import img14 from '../../assets/img/gallery/14.jpg';
import img15 from '../../assets/img/gallery/15.jpg';
import img16 from '../../assets/img/gallery/16.jpg';
//import img17 from '../../assets/img/gallery/17.jpg';
import img18 from '../../assets/img/gallery/18.jpg';
import img19 from '../../assets/img/gallery/19.jpg';
import img20 from '../../assets/img/gallery/20.jpg';
import img21 from '../../assets/img/gallery/21.jpg';
import img22 from '../../assets/img/gallery/22.jpg';
import img23 from '../../assets/img/gallery/23.jpg';
import img24 from '../../assets/img/gallery/24.jpg';
import img25 from '../../assets/img/gallery/25.jpg';
import img30 from '../../assets/img/gallery/30.jpg';
import img31 from '../../assets/img/gallery/31.jpg';
import img32 from '../../assets/img/gallery/32.jpg';
import img33 from '../../assets/img/gallery/33.jpg';
import img34 from '../../assets/img/gallery/34.jpg';
import img35 from '../../assets/img/gallery/35.jpg';
import img36 from '../../assets/img/gallery/36.jpg';
import img37 from '../../assets/img/gallery/37.jpg';
import img38 from '../../assets/img/gallery/38.jpg';
import img39 from '../../assets/img/gallery/39.jpg';
import img40 from '../../assets/img/gallery/40.jpg';
import img41 from '../../assets/img/gallery/41.jpg';
import img42 from '../../assets/img/gallery/42.jpg';
import img43 from '../../assets/img/gallery/43.jpg';
import img44 from '../../assets/img/gallery/44.jpg';
import img45 from '../../assets/img/gallery/45.jpg';
import img46 from '../../assets/img/gallery/46.jpg';
import img47 from '../../assets/img/gallery/47.jpg';
import img48 from '../../assets/img/gallery/48.jpg';
import img49 from '../../assets/img/gallery/49.jpg';
import img50 from '../../assets/img/gallery/50.jpg';
import img51 from '../../assets/img/gallery/51.jpg';
import img52 from '../../assets/img/gallery/52.jpg';
import img53 from '../../assets/img/gallery/53.jpg';
import img54 from '../../assets/img/gallery/54.jpg';
import img55 from '../../assets/img/gallery/55.jpg';
import img56 from '../../assets/img/gallery/56.jpg';
import img57 from '../../assets/img/gallery/57.jpg';
import img58 from '../../assets/img/gallery/58.jpg';
import img59 from '../../assets/img/gallery/59.jpg';
import img60 from '../../assets/img/gallery/60.jpg';
import img61 from '../../assets/img/gallery/61.jpg';
import img62 from '../../assets/img/gallery/62.jpg';
import img63 from '../../assets/img/gallery/63.jpg';
import img64 from '../../assets/img/gallery/64.jpg';
import img65 from '../../assets/img/gallery/65.jpg';
import img66 from '../../assets/img/gallery/66.jpg';
import img67 from '../../assets/img/gallery/67.jpg';
import img68 from '../../assets/img/gallery/68.jpg';
import img69 from '../../assets/img/gallery/69.jpg';
import img72 from '../../assets/img/gallery/72.jpg';





const images = [
  {
    original: img1,
    thumbnail: img1,
  },
/*   {
    original: img2,
    thumbnail: img2,
  }, */
  {
    original: img3,
    thumbnail: img3,
  },
  {
    original: img4,
    thumbnail: img4,
  },
  {
    original: img5,
    thumbnail: img5,
  },
  {
    original: img6,
    thumbnail: img6,
  },
  {
    original: img7,
    thumbnail: img7,
  },
  {
    original: img8,
    thumbnail: img8,
  },
  {
    original: img9,
    thumbnail: img9,
  },
  {
    original: img10,
    thumbnail: img10,
  },
  {
    original: img11,
    thumbnail: img11,
  },
  {
    original: img12,
    thumbnail: img12,
  },
  {
    original: img13,
    thumbnail: img13,
  },
  {
    original: img14,
    thumbnail: img14,
  },
  {
    original: img15,
    thumbnail: img15,
  },
  {
    original: img16,
    thumbnail: img16,
  },
/*   {
    original: img17,
    thumbnail: img17,
  }, */
  {
    original: img18,
    thumbnail: img18,
  },
  {
    original: img19,
    thumbnail: img19,
  },
  {
    original: img20,
    thumbnail: img20,
  },
  {
    original: img21,
    thumbnail: img21,
  },
  {
    original: img22,
    thumbnail: img22,
  },
  {
    original: img23,
    thumbnail: img23,
  },
  {
    original: img24,
    thumbnail: img24,
  },
  {
    original: img25,
    thumbnail: img25,
  },
  {
    original: img30,
    thumbnail: img31,
  },
  {
    original: img32,
    thumbnail: img32,
  }, {
    original: img33,
    thumbnail: img33,
  }, {
    original: img34,
    thumbnail: img34,
  },
  {
    original: img35,
    thumbnail: img35,
  },
  {
    original: img36,
    thumbnail: img36,
  },
  {
    original: img37,
    thumbnail: img37,
  },
  {
    original: img38,
    thumbnail: img38,
  }, {
    original: img39,
    thumbnail: img39,
  },
  {
    original: img40,
    thumbnail: img40,
  },
  {
    original: img41,
    thumbnail: img41,
  }, {
    original: img42,
    thumbnail: img42,
  },
  {
    original: img43,
    thumbnail: img43,
  }, {
    original: img44,
    thumbnail: img44,
  }, {
    original: img45,
    thumbnail: img45,
  },
  {
    original: img46,
    thumbnail: img46,
  },
  {
    original: img47,
    thumbnail: img47,
  },
  {
    original: img48,
    thumbnail: img48,
  },
  {
    original: img49,
    thumbnail: img49,
  }, {
    original: img50,
    thumbnail: img50,
  }, {
    original: img51,
    thumbnail: img51,
  }, {
    original: img52,
    thumbnail: img52,
  }, {
    original: img53,
    thumbnail: img53,
  }, {
    original: img54,
    thumbnail: img54,
  }, {
    original: img55,
    thumbnail: img55,
  }, {
    original: img56,
    thumbnail: img56,
  }, {
    original: img57,
    thumbnail: img57,
  }, {
    original: img58,
    thumbnail: img59,
  }, {
    original: img60,
    thumbnail: img60,
  }, {
    original: img61,
    thumbnail: img61,
  }, {
    original: img62,
    thumbnail: img62,
  }, {
    original: img63,
    thumbnail: img63,
  }, {
    original: img64,
    thumbnail: img64,
  }, {
    original: img65,
    thumbnail: img65,
  }, {
    original: img66,
    thumbnail: img66,
  }, {
    original: img67,
    thumbnail: img67,
  }, {
    original: img68,
    thumbnail: img68,
  }, {
    original: img69,
    thumbnail: img69,
  }, {
    original: img72,
    thumbnail: img72,
  }

];

export default images