import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import {FaHotTub,FaEye,FaParking,FaBed,FaCloudSun,FaWifi,FaSwimmingPool} from 'react-icons/fa';
import FeatureElement from './FeatureElement';


class Corefeature extends Component {
  render() {
    const {t} = this.props;

    return (
        <section id="core-features" className="core-feature-section bg-white pt-115 pb-115">
        <div className="container">
          <div className="section-title text-center mb-50">
            <h2>{t('featuresTitle')}</h2>
          </div>
          {/* Featre Loop */}
          <div className="row features-loop">
            <div className="col-lg-4 col-sm-6 order-1">
              <FeatureElement title={t('f1Title')} about={t('f1About')} icon={<FaWifi/>}/>
            </div>
            <div className="col-lg-4 col-sm-6 order-2">
              <FeatureElement title={t('f2Title')} about={t('f2About')} icon={<FaSwimmingPool/>}/>
            </div>
            <div className="col-lg-4 col-sm-6 order-3 order-sm-4 order-lg-3">
              <FeatureElement title={t('f3Title')} about={t('f3About')} icon={<FaEye/>}/>
            </div>
            <div className="col-lg-4 col-sm-6 order-4 order-sm-3 order-lg-4">
              <FeatureElement title={t('f4Title')} about={t('f4About')} icon={<FaBed/>}/>
            </div>
            <div className="col-lg-4 col-sm-6 order-5">
              <FeatureElement title={t('f5Title')} about={t('f5About')} icon={<FaCloudSun/>}/>
            </div>
            <div className="col-lg-4 col-sm-6 order-6">
              <FeatureElement title={t('f6Title')} about={t('f6About')} icon={<FaParking/>}/>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withNamespaces()(Corefeature);
