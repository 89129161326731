import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import { withNamespaces } from 'react-i18next';

import Preloader from './pages/components/Preloader';
import Hometwo from './pages';

function App({t}) {

  console.log(t('Welcome to React'));

  return (
      <Router>
         <Preloader />
        <Switch>
          <Route path='/' component={Hometwo} />
          
       
        </Switch>
      </Router>
  );
}

export default withNamespaces()(App);
