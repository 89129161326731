import React, { Component } from 'react';
import { Link } from 'react-scroll'; 
import ReactWOW from 'react-wow'
import { withNamespaces } from 'react-i18next';
import {Carousel,Col,Row} from 'react-bootstrap';


import bannerimg1 from '../../assets/img/gallery/DJI_0246.jpg';
import bannerimg2 from '../../assets/img/gallery/DJI_0268.jpg';
import bannerimg3 from '../../assets/img/gallery/DJI_0255.jpg';

const bannerPosts = [
  {
      photo: bannerimg1,
      tag: 'The ultimate luxury experience',
      taganimation: '.6s',
      title:"Welcome to Villa Gojun",
      titleanimation:'.9s',
      btn1:'take a tour',
      btn1animation:'1.1s',
      btn1url:'gallery',
      btn2:'Learn More',
      btn2animation:'1.3s',
      btn2url:'about',
  },
  {
      photo: bannerimg2,
      tag: 'The ultimate luxury experience',
      taganimation: '.6s',
      title:'Welcome to Villa Gojun',
      titleanimation:'.9s',
      btn1:'take a tour',
      btn1animation:'1.1s',
      btn1url:'gallery',
      btn2:'Learn More',
      btn2animation:'1.3s',
      btn2url:'about',
  },
  {
    photo: bannerimg3,
    tag: 'The ultimate luxury experience',
    taganimation: '.6s',
    title:"Welcome to Villa Gojun",
    titleanimation:'.9s',
    btn1:'take a tour',
    btn1animation:'1.1s',
    btn1url:'gallery',
    btn2:'Learn More',
    btn2animation:'1.3s',
    btn2url:'about',
},
];



class Banner extends Component {
  render() {
    const {t} = this.props;
    return (

      <Carousel id="home" className="banner-area">
        {bannerPosts.map((item, i) => (
  <Carousel.Item>
    <img
      className="d-block w-100 h-screen background"
      src={item.photo}
      alt="First slide"
    />
    <Carousel.Caption>
    <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="banner-content text-center">
                    <ReactWOW animation="fadeInLeft" data-delay={item.titleanimation}>
                  <h1 className="title">{t('title')}
                  </h1></ReactWOW>
                  <ul>
                    <Row>
                  <Col xs={6} md={6}>
                  <ReactWOW animation="fadeInUp" data-delay={item.btn1animation}>
                    <li>
                      <Link className="main-btn btn-filled banner-btn" to="gallery" smooth={true}>{t('btn1')}</Link>
                    </li>
                    </ReactWOW>
                    </Col>
                    <Col xs={6} md={6}>
                    <ReactWOW animation="fadeInUp" data-delay={item.btn2animation}>
                    <li>
                      <Link className="main-btn btn-filled banner-btn" to="about" smooth={true}>{t('btn2')}</Link>
                    </li>
                    </ReactWOW>
                    </Col>
                    </Row>
                  </ul>
                </div>
              </div>
            </div>
          </div>
    </Carousel.Caption>
  </Carousel.Item>
        ))}
</Carousel>

    );
  }
}

export default withNamespaces()(Banner);
