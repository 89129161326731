import React, { Component } from 'react'
import Navigation from './components/Navigation';
import Footertwo from './components/Footertwo';
import Mainbanner from './components/Banner';
import About from './components/About';
import Corefeature from './components/Corefeature';
import Amenities from './components/Amenities';
import Gallery from './components/Gallery';
import VideoBlock from './components/Videoblock';
import '../i18n';

class Hometwo extends Component {

  constructor(props){
    super(props)
    this.state = {
      arrDate: "",
      depDate: "",
      email:""
    }
  }

  render() {
    return (
      <div>
        <Navigation />
        {/*====== BANNER PART START ======*/}
        <Mainbanner/>
        {/*====== BANNER PART ENDS ======*/}
        {/*====== BOOKING FORM START ======*/}
{/*         <Bookingform 
          onChange={event => console.log(this.setState({[event.target.name]: event.target.value,
             [event.target.name]: event.target.value,
              [event.target.name]: event.target.value}),
              'arrdate change') }



        /> */} {/** mozda da mozda ne... */}
        {/*====== BOOKING FORM END ======*/}
        {/*====== TEXT BLOCK START ======*/}
        <About /> {/**ABOUT SECTION */}
        {/*====== TEXT BLOCK END ======*/}
       
        {/*====== CORE FEATURES START ======*/}
        <Corefeature/> {/** TOP FEATURE */}
        {/*====== CORE FEATURES END ======*/}
        {/*====== FEATURE ROOM START ======*/}
        {/* <Featureroom/> */}
        <Gallery />
        {/*====== FEATURE ROOM END ======*/}
         {/*====== COUNTER UP START ======*/}
          <Amenities/> {/**amenities */}
        {/*====== COUNTER UP END ======*/}
        {/*====== TESTIMONIAL SLIDER START ======*/}
        {/* <Testimonial/> */}
        {/* Testimonial sekcija iskljucena jer jos nema recenzija*/}
        {/*====== TESTIMONIAL SLIDER END ======*/}
        {/*====== VIDEO WRAP START ======*/}
        {/* <Videowrap/> */}
        <VideoBlock />
        {/*====== VIDEO WRAP END ======*/}
        <Footertwo arrival={this.state.arrDate} departure={this.state.depDate} email={this.state.email}/>
      </div>
    );
  
  }
}

export default Hometwo;
