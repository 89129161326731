import React, { Component } from 'react';
import ImageGallery from 'react-image-gallery';
 import "react-image-gallery/styles/scss/image-gallery.scss";
 import "react-image-gallery/styles/css/image-gallery.css";
 import images from './Images';
 import { withNamespaces } from 'react-i18next';

class Gallery extends Component {
  render() {
    const img = images;
    const {t} = this.props;

    return (
        <section className="room-slider bg-white pb-100 pt-115" id="gallery">
        <div className="container-fluid p-0">
          <div className="section-title mb-80 text-center">
            <h2>{t('nav4')}</h2>
          </div>
          <ImageGallery items={img}
           />
        </div>
      </section>
      
    );
  }
}

export default withNamespaces()(Gallery);
